export const lerp = (a, b, n) => ((1 - n) * a) + (n * b);

export const isMobile = () => typeof window !== 'undefined' && window.innerWidth < 1024;

export const isIOS = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const isEdge = () => typeof window !== 'undefined' && window.navigator.userAgent.indexOf('Edge') > -1;

export const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query) => window.matchMedia(query).matches;
  // eslint-disable-next-line no-mixed-operators, no-undef
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

export const isSafari = () => typeof navigator !== 'undefined'
  && navigator.vendor
  && navigator.vendor.indexOf('Apple') > -1
  && navigator.userAgent
  && navigator.userAgent.indexOf('CriOS') === -1
  && navigator.userAgent.indexOf('Chrome') === -1
  && navigator.userAgent.indexOf('FxiOS') === -1;

export const inFullpage = () => document.location.href.match(/fullPage=true/gi);

export const flatAndSort = (object, key) => {
  const keys = Object.keys(object);
  const sortedKeys = keys.sort((a, b) => object[a][key] - object[b][key]);
  const sortedObject = [];
  sortedKeys.forEach((k) => sortedObject.push({ key: k, ...object[k] }));
  return sortedObject;
};

/* eslint-disable no-unused-vars, no-restricted-properties */
export const calcPerf = () => {
  const PERF_BAD = 0;
  const PERF_LOW = 1;
  const PERF_GOOD = 2;
  const PERF_HIGH = 3;
  let PERF = PERF_BAD;

  const getPerfs = () => {
    let array = [];
    const start = (window.performance || Date).now();
    for (let i = 0; i < 20000; i += 1) {
      array = Math.pow(Math.sin(Math.random()), 2);
    }
    const end = (window.performance || Date).now();
    return end - start;
  };

  const timing = getPerfs();
  if (timing < 7) PERF = PERF_HIGH;
  else if (timing < 14) PERF = PERF_GOOD;
  else if (timing < 22) PERF = PERF_LOW;
  else PERF = PERF_BAD;
  return PERF;
};

export const getParamUrl = (paramName) => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  return params.get(paramName);
};

export const sendEvent = (category) => {
  if (!window.ga) return;
  console.log('Sending GA Event:', category);
  window.ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: '',
    eventLabel: '',
  });
};

<template>
  <div class="c-app-rotate-device rotate-device">
    <h2 ref="title">
      <span>
        <span class="u-split-container">
          <span class="split-line">
            Rotate
          </span>
        </span>
      </span>
      <span>
        <span class="u-split-container">
          <span class="split-line">
            Your
          </span>
        </span>
      </span>
      <span>
        <span class="u-split-container">
          <span class="split-line">
            Device
          </span>
        </span>
      </span>
    </h2>
    <h1 ref="number">Thank you!</h1>
  </div>
</template>

<script>
export default {
  name: 'AppRotateDevice',
};
</script>

<style lang="scss" scoped>
.rotate-device {
  @extend %full;

  background-color: $color-bg;

  @supports (background-blend-mode: multiply) {
    background-blend-mode: multiply;
    background-image: url(/assets/img/base/shadow-game.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  z-index: 999;

  display: none;
  align-items: center;
  justify-content: center;

  @include mq($until: ipadL, $and: '(orientation: landscape)') {
    display: flex;
  }

  @include mq($and: $is-not-touch-device) {
    display: none;
  }

  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  text-align: center;

  h1 {
    font-weight: normal;
    font-size: 18px;
  }

  h2 {
    font-family: 'Tropiline';
    font-weight: normal;
    margin-top: 25px;
    font-size: rem(50px);
    font-size: calc(50px * var(--rw));
    line-height: 1;
    color: $black;

    @include mq(ipadP) {
      font-size: rem(120px);
      font-size: calc(120px * var(--rw));
    }

    span {
      display: block;

      &:nth-child(2) {
        transform: translateY(-15%);
      }
      &:nth-child(3) {
        transform: translateY(-30%);
      }
    }
  }
}
</style>

import { gsap } from 'gsap';

export function bigTitleSet(el) {
  const linesTitle = [...el.querySelectorAll('.split-line')];

  gsap.set(el, { autoAlpha: 0 });
  gsap.set(linesTitle, { yPercent: 110 });
}

export function bigTitleIn(el) {
  const linesTitle = [...el.querySelectorAll('.split-line')];
  const tl = gsap.timeline();

  tl.addLabel('start')
    .set(el, { autoAlpha: 1 }, 'start')
    .to(linesTitle, {
      yPercent: 0,
      duration: 1.8,
      ease: 'coor',
      stagger: 0.25,
    }, 'start')
    .set(el, { pointerEvents: 'auto' });

  return tl;
}

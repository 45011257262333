<template>
  <button
    :class="`c-button button button--${theme}`"
    :data-animation="isAnimated ? 'button' : isAnimated"
    :data-id="isAnimated ? 'scrollAnimations' : isAnimated"
    @mouseenter="enterHover"
    @mouseleave="leaveHover"
  >
    <span class="button__bg" />
    <span class="button__content hover__container">
      <span class="hover__text" data-hover="first">{{ content }}</span>
      <span class="hover__text hover__text--fake" data-hover="second">{{ content }}</span>
    </span>
  </button>
</template>

<script>
import { setHoverVoice, hoverVoice } from '@/scripts/animations/hoverVoice';

export default {
  name: 'AppButton',

  props: {
    content: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: 'black',
    },
    isAnimated: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  mounted() {
    setHoverVoice(this.$el);
  },

  methods: {
    enterHover(e) {
      hoverVoice(e.target, 'enter');
    },
    leaveHover(e) {
      hoverVoice(e.target, 'leave');
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  padding: 12px 20px;

  border-radius: 50px;
  cursor: pointer;

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    border-radius: 50px;
  }

  &__content {
    position: relative;
    margin: 0;

    font-size: inherit;

    span {
      white-space: nowrap;
    }
  }

  &.button--black {
    color: $white;

    .button__bg {
      background-color: $black;
    }

    .button__content {
      text-transform: uppercase;
    }
  }

  &.button--white {
    color: $black;
    padding: 15px 30px;

    .button__bg {
      background-color: $white;
    }
  }
}
</style>

// import { gsap } from 'gsap';
import {
  Renderer,
  Program,
  Camera,
  Mesh,
  Transform,
  Plane,
  Texture,
  Vec2,
} from 'ogl/src';
import eventHub from '@/scripts/eventHub';
import vertex from './shaders/vertex.vs';
import fragment from './shaders/fragment.fs';

export default class Flag {
  constructor(container) {
    this.container = container;

    this.time = 0;
    this.gl = null;
    this.scene = null;
    this.camera = null;
    this.textures = new Map();

    this.load().then(() => this.init());
  }

  loadPicture(id) {
    const image = new Image();
    const errorHandler = () => new Error(`Failed to load image's URL: ${id}`);
    const promiseFunction = (resolve) => {
      if (typeof image.decode !== 'undefined') {
        image.decoding = 'sync';
        image.src = `/assets/img/textures/${id}.png`;
        image.decode().then(() => {
          if (typeof window.requestIdleCallback !== 'undefined') {
            requestAnimationFrame(() => requestIdleCallback(resolve));
          } else {
            requestAnimationFrame(() => requestAnimationFrame(resolve));
          }
        });
      } else {
        const imageHandler = () => {
          image.removeEventListener('load', imageHandler);
          image.removeEventListener('error', errorHandler);
          image.remove();

          resolve();
        };

        image.addEventListener('load', imageHandler());
        image.addEventListener('error', errorHandler());
      }
    };

    const promise = new Promise(promiseFunction);

    return promise.then(() => {
      this.textures.set(id, image);
    });
  }

  async load() {
    const items = [
      'usa',
      'italy',
      'canada',
      'australia',
      'uk',
      'brazil',
      'turkey',
      'switzerland',
    ];
    const promises = [];

    items.forEach((item) => promises.push(this.loadPicture(item)));

    await Promise.all(promises);
  }

  init() {
    this.renderer = new Renderer({ dpr: 2, alpha: true });
    const { gl } = this.renderer;
    this.gl = gl;

    this.container.appendChild(gl.canvas);
    gl.clearColor(0, 0, 0, 0);

    this.camera = new Camera(gl, { fov: 35 });
    this.camera.position.set(0, 0, 7);
    this.camera.lookAt([0, 0, 0]);

    this.texture = new Texture(gl, {
      width: 382,
      height: 249,
      generateMipmaps: false,
    });

    this.program = new Program(gl, {
      vertex,
      fragment,
      transparent: true,
      uniforms: {
        uTime: { value: 0 },
        uResolution: { value: new Vec2(0, 0) },
        tMap: { value: this.texture },
      },
    });

    this.scene = new Transform();

    const planeGeometry = new Plane(gl, {
      width: 7.6,
      height: 5,
      widthSegments: 15,
      heigthSegments: 9,
    });
    const plane = new Mesh(gl, { geometry: planeGeometry, program: this.program });
    plane.position.set(0, 0, 0);
    plane.setParent(this.scene);

    this.resize();

    this.handlerResize = () => this.resize();
    eventHub.$on('windowResize', this.handlerResize);
  }

  setFlag(id) {
    this.texture.image = this.textures.get(id);
  }

  resize() {
    const width = Math.max(50, window.innerWidth / 20);
    const height = width * 0.7;

    this.renderer.setSize(width, height);
    this.camera.perspective({ aspect: width / height });

    this.program.uniforms.uResolution.value.x = width;
    this.program.uniforms.uResolution.value.y = height;
  }

  render(t) {
    this.time += t;
    this.program.uniforms.uTime.value = -this.time * 0.004;

    this.renderer.render({ scene: this.scene, camera: this.camera });
  }

  destroy() {
    this.gl.deleteProgram(this.program.program);
    this.textures = null;
    this.scene = null;
    this.program = null;
    this.camera = null;
    this.renderer = null;
    this.gl = null;
    this.time = 0;
    eventHub.$off('windowResize', this.handlerResize);
  }
}

import { gsap } from 'gsap';

function enterCards() {
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to('.controls__item--progress', {
      scaleX: 1,
      transformOrigin: (i) => i < 3 ? '100% 50%' : '0% 50%', // eslint-disable-line
      ease: 'coor',
      duration: 1.25,
      stagger: {
        amount: 0.2,
        from: 'center',
        axis: 'x',
        grid: [1, 6],
      },
    }, 'start')
    .to('.controls__item--icon', {
      scale: 1,
      transformOrigin: '50% 50%',
      ease: 'coor',
      duration: 1.25,
      stagger: {
        amount: 0.2,
        from: 'center',
        axis: 'x',
        grid: [1, 5],
      },
    }, 'start+=0.25');

  return tl;
}
function leaveCards() {
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to('.controls__item--progress', {
      scaleX: 0,
      transformOrigin: (i) => i < 3 ? '100% 50%' : '0% 50%', // eslint-disable-line
      ease: 'coor',
      duration: 1.25,
      stagger: {
        amount: 0.2,
        from: 'edgs',
        axis: 'x',
        grid: [1, 6],
      },
    }, 'start')
    .to('.controls__item--icon', {
      scale: 0,
      transformOrigin: '50% 50%',
      ease: 'coor',
      duration: 1.25,
      stagger: {
        amount: 0.2,
        from: 'edges',
        axis: 'x',
        grid: [1, 5],
      },
    }, 'start+=0.25');

  return tl;
}
function enterSing() {
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to('.lyrics__container', {
      y: 0,
      autoAlpha: 1,
      ease: 'coor',
      duration: 1.5,
    }, 'start');

  return tl;
}
function leaveSing() {
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to('.lyrics__container', {
      y: 80,
      autoAlpha: 0,
      ease: 'coor',
      duration: 1.5,
    }, 'start');

  return tl;
}

export default function switchMode(mode) {
  const tl = gsap.timeline();
  tl.addLabel('start');

  if (mode === 'sing') {
    tl.addLabel('start')
      .add(leaveCards, 'start')
      .add(enterSing, 'start+=0.75');
  } else {
    tl.addLabel('start')
      .add(leaveSing, 'start')
      .add(enterCards, 'start+=1');
  }

  return tl;
}

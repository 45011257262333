import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showLoader: true,
    showMenu: false,
    menuClose: '',
    showHireModal: false,
    hireModalCountry: '',
    firstIntroDone: false,
  },
  mutations: {
    REMOVE_LOADER(state) {
      state.showLoader = false;
    },
    TOGGLE_MENU(state, payload = '') {
      state.showMenu = !state.showMenu;
      state.menuClose = payload;
    },
    OPEN_HIRE_MODAL(state, payload = '') {
      state.showHireModal = true;
      state.hireModalCountry = payload;
    },
    CLOSE_HIRE_MODAL(state) {
      state.showHireModal = false;
    },
    REMOVE_INTRO(state) {
      state.firstIntroDone = true;
    },
  },
  actions: {
  },
  modules: {
  },
});

import { gsap } from 'gsap';
import {
  Renderer,
  Triangle,
  Program,
  Mesh,
  Vec2,
  Color,
} from 'ogl/src';
import eventHub from '@/scripts/eventHub';
import raf from '@/scripts/raf';
import vertex from './shaders/vertex.vs';
import fragment from './shaders/fragment.fs';

export default class ForegroundStage {
  constructor(container) {
    this.container = container;
    this.renderer = new Renderer({ alpha: true });

    this.gl = null;
    this.mesh = null;

    this.init();
  }

  init() {
    const { gl } = this.renderer;
    this.gl = gl;

    this.container.appendChild(gl.canvas);
    gl.clearColor(0, 0, 0, 0);

    const geometry = new Triangle(gl);

    this.program = new Program(gl, {
      vertex,
      fragment,
      // transparent: true,
      uniforms: {
        uResolution: { value: new Vec2(0, 0) },
        uDirection: { value: -1 },
        uPosY: { value: 0.0 },
        uAmplitude: { value: 0 },
        uColor: { value: new Color('#E0D8D3') },
      },
    });

    this.mesh = new Mesh(gl, { geometry, program: this.program });

    this.resize();

    this.handlerResize = () => this.resize();
    eventHub.$on('windowResize', this.handlerResize);
  }

  transition(main) {
    const { color } = main.dataset;
    const newColor = new Color(color);
    this.gl.clearColor(...newColor, 0);
    raf.add(() => this.render(), 'canvasRender');

    const tl = gsap.timeline({
      onComplete: () => {
        this.reset();

        const menuVoices = [...document.querySelectorAll('.o-header .header__item a'), ...document.querySelectorAll('.o-header .header__item button')];
        gsap.set(menuVoices, { pointerEvents: 'auto' });
        gsap.set(document.querySelector('.app'), { pointerEvents: 'auto' });
      },
    });

    tl.addLabel('start')
      .set(this.container, { autoAlpha: 1 }, 'start')
      .add(() => { this.program.uniforms.uColor.value = newColor; }, 'start')
      .to(this.program.uniforms.uPosY, {
        value: 1.01,
        duration: 1,
        ease: 'power3.inOut',
      }, 'start')
      .to(this.program.uniforms.uAmplitude, {
        value: -0.04,
        duration: 0.5,
        ease: 'power2',
      }, 'start+=0.1')
      .to(this.program.uniforms.uAmplitude, {
        value: 0,
        duration: 0.3,
        ease: 'power3.out',
      }, 'start+=0.7')
      .set(document.body, { backgroundColor: color })
      .set(main, { backgroundColor: color });

    return tl;
  }

  reset() {
    gsap.set(this.program.uniforms.uPosY, { value: 0 });
    gsap.set(this.program.uniforms.uAmplitude, { value: 0 });
    gsap.set(this.container, { autoAlpha: 0 });
    raf.remove('canvasRender');
  }

  resize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.program.uniforms.uResolution.value.x = window.innerWidth;
    this.program.uniforms.uResolution.value.y = window.innerHeight;
  }

  render() {
    this.renderer.render({ scene: this.mesh });
  }
}

import { gsap } from 'gsap';
import { fadeSet, fadeIn } from './fade';
import { circularButtonSet, circularButtonIn } from './circularButton';

export function footerSet(el) {
  const headings = [...el.querySelectorAll('.item__heading')];
  const content = [...el.querySelectorAll('.item__content')];
  const socialIcons = [...el.querySelectorAll('.social-list__item')];
  const circularButton = el.querySelector('.scroll-arrow');

  fadeSet(headings, { x: 0, y: 50 });
  fadeSet(content, { x: 0, y: 50 });
  circularButtonSet(circularButton);
  gsap.set(socialIcons, { scale: 0, transformOrigin: '50% 50%' });
}

export function footerIn(el) {
  const headings = [...el.querySelectorAll('.item__heading')];
  const content = [...el.querySelectorAll('.item__content')];
  const socialIcons = [...el.querySelectorAll('.social-list__item')];
  const circularButton = el.querySelector('.scroll-arrow');

  const tl = gsap.timeline();

  tl.addLabel('start')
    .add(fadeIn(headings), 'start')
    .add(fadeIn(content), 'start+=0.35')
    .add(circularButtonIn(circularButton), 'start+=0.35')
    .to(socialIcons, {
      scale: 1,
      autoAlpha: 1,
      duration: 1,
      stagger: 0.35,
      ease: 'coor',
    }, 'start+=0.6');

  return tl;
}

import { gsap } from 'gsap';

export default function menu(el) {
  const bg = el.querySelector('.navigator__bg');
  const titles = [...el.querySelectorAll('.split-line')];
  const eyelets = [...el.querySelectorAll('.navigator__number')];
  const footer = el.querySelector('.navigator__footer');
  const tl = gsap.timeline({ paused: true, smoothChildTiming: true });

  tl.addLabel('start')
    .fromTo(bg, { scaleY: 0 }, {
      scaleY: 1,
      transformOrigin: '0 100%',
      ease: 'coor',
      duration: 1.5,
    }, 'start')
    .fromTo(footer, { y: 20, autoAlpha: 0 }, {
      y: 0,
      autoAlpha: 1,
      ease: 'coor',
      duration: 1.5,
    }, 'start+=0.3')
    .fromTo(titles, { yPercent: 120 }, {
      yPercent: 0,
      duration: 1.5,
      ease: 'coor',
      stagger: 0.25,
    }, 'start+=0.6')
    .fromTo(eyelets, { yPercent: 60, autoAlpha: 0 }, {
      yPercent: 0,
      duration: 1.5,
      autoAlpha: 1,
      ease: 'coor',
      stagger: 0.25,
    }, 'start+=0.9');

  return tl;
}

import { gsap } from 'gsap';
import { SplitText } from '@/assets/libs/SplitText';

export function carouselArrowSet(el) {
  const split = new SplitText(el.querySelector('.u-split-container'), { type: 'words', wordsClass: 'split-word' });
  const line = el.querySelector('rect');
  const arrowCap = el.querySelector('polygon');

  gsap.set(split.words, { yPercent: 100 });
  gsap.set(line, { x: -10, scaleX: 0, transformOrigin: '0% 50%' });
  gsap.set(arrowCap, { x: -10, clipPath: 'inset(0.01% -0.02% -0.03% 100%)' });
}

export function carouselArrowIn(el) {
  const words = [...el.querySelectorAll('.split-word')];
  const line = el.querySelector('rect');
  const arrowCap = el.querySelector('polygon');
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to(words, {
      yPercent: 0,
      duration: 1.5,
      stagger: 0.15,
      ease: 'coor',
    }, 'start')
    .to(line, {
      x: 0,
      scaleX: 1,
      duration: 2,
      ease: 'coor',
      clearProps: 'all',
    }, 'start+=0.5')
    .to(arrowCap, {
      x: 0,
      duration: 2,
      ease: 'coor',
      clearProps: 'all',
    }, 'start+=0.5')
    .to(arrowCap, {
      clipPath: 'inset(0% -0.01% -0.02% -0.03%)',
      duration: 1.5,
      ease: 'coor',
      clearProps: 'all',
    }, 'start+=1');

  return tl;
}

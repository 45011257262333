import { gsap } from 'gsap';
import { SplitText } from '@/assets/libs/SplitText';

export function titleSplitSet(el) {
  const splitCont = new SplitText(el, { type: 'lines', linesClass: 'u-split-container' });

  splitCont.lines.forEach((cont) => {
    const splitLine = new SplitText(cont, { type: 'lines', linesClass: 'split-line' });
    gsap.set(splitLine.lines, { yPercent: 120 });
  });

  gsap.set(el, { autoAlpha: 0 });
}

export function titleSplitIn(el) {
  const line = [...el.querySelectorAll('.split-line')];
  const tl = gsap.timeline();

  tl.addLabel('start')
    .set(el, { autoAlpha: 1 }, 'start')
    .to(line, {
      yPercent: 0,
      duration: 2.2,
      ease: 'coor',
      stagger: 0.25,
    }, 'start');

  return tl;
}

export function titleSplitOut(el) {
  return gsap.to(el, {
    y: -50,
    autoAlpha: 0,
    duration: 1.8,
    ease: 'coor',
  });
}

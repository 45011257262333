import { gsap } from 'gsap';

export function fadeSet(el, { x, y } = { x: 0, y: 0 }) {
  gsap.set(el, { x, y, autoAlpha: 0 });
}

export function fadeIn(el, stagger = 0) {
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to(el, {
      autoAlpha: 1,
      duration: 1.2,
      stagger,
    }, 'start')
    .to(el, {
      x: 0,
      y: 0,
      duration: 1.2,
      stagger,
      ease: 'coor',
      clearProps: 'all',
    }, 'start');

  return tl;
}

export function fadeOut(el, { x, y } = { x: 0, y: 0 }) {
  return gsap.to(el, {
    x,
    y,
    autoAlpha: 0,
    duration: 1.2,
    ease: 'coor',
  });
}

<template>
  <strong class="c-logo logo" :class="{ 'logo--burger-open' : $store.state.showMenu }" data-theme="">
    <router-link class="logo__container" to="/" aria-label="Link to homepage">
      <img class="logo__image" src="/assets/img/misc/the-hiring-chain-logo-top.png" alt="">
      <img class="logo__image" src="/assets/img/misc/the-hiring-chain-logo-bottom.png" alt="">
    </router-link>
  </strong>
</template>

<script>
export default {
  name: 'AppLogo',

  mounted() {
    this.$eventHub.$on('changeTheme', (theme) => this.$el.setAttribute('data-theme', theme));
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: fixed;
  top: 3.5vh;
  top: calc(var(--vh, 1vh) * 3.5);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  filter: invert(0);

  z-index: 25;

  transition: filter 0.5s ease-in-out;

  @include mq(ipadP) {
    top: 4.35vh;
    top: calc(var(--vh, 1vh) * 4.35);
  }

  &[data-theme="light"] {
    filter: invert(1);
  }

  &.logo--burger-open {
    filter: invert(0) !important;
  }

  &__container {
    position: relative;
    display: block;
    width: 50vw;

    pointer-events: auto;

    @include mq(ipadP) {
      width: 17vw;
    }

    img {
      position: relative;
      width: 100%;
      height: auto;

      &:last-child {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>

<template>
  <main class="home" data-color="#000">
    <video
      ref="video"
      crossorigin="anonymous"
      preload="auto"
      autoload="true"
      playsinline
      :muted="isTouchDevice ? true : false"
      type="video/mp4"
      @click="togglePlay"
    >
      <source src="/assets/video/coordown.mp4" type="video/mp4"/>
    </video>

    <div class="home__intro" ref="intro">
      <p>
        People with Down syndrome want to work for the same reasons as anyone else. But they often face barriers, prejudice and lack of opportunities. You can help make a change.
      </p>
    </div>

    <div class="home__logo home__logo--hiring">
      <p>Start the</p>
      <h1>Hiring Chain</h1>
    </div>

    <div class="home__logo home__logo--performed">
      <p>performed by</p>
      <img src="/assets/img/misc/performadebysting.png" alt="Performed by Sting">
    </div>

    <div class="home__logo home__logo--supported">
      <img src="/assets/img/misc/coordown.png" alt="CoorDown supported by LinkedIn">
      <p>supported by</p>
      <img src="/assets/img/misc/linkedin.png" alt="CoorDown supported by LinkedIn">
    </div>

    <div class="home__logo home__logo--syndrome">
      <img src="/assets/img/misc/wdsd.png" alt="World Down syndrome Day logo">
    </div>

    <div class="lyrics">
      <div class="lyrics__container">
        <p v-for="(words, indexLine) in video.lyrics" :key="`line-${indexLine}`" :data-line="indexLine">
          <span v-for="(word, indexWord) in words" :key="`line-${indexWord}`" :data-word="indexWord">
            {{ word.word }}&nbsp;
          </span>
        </p>
      </div>
    </div>

    <div class="video__controls">
      <ul class="controls__container" ref="progressContainer">
        <li
          v-for="(phase, i) in video.phases"
          :key="i"
          :class="[ 'controls__item', i % 2 === 1 ? 'controls__item--icon' : 'controls__item--progress']"
        >
          <VideoIcon
            v-if="i % 2 === 1"
            :icon="phase.icon"
            :isActive="phase.isActive"
            :clicked="phase.clicked"
            aria-label="Video phase button"
            @click.native="phase.isActive && !phase.clicked ? showPhase(phase) : phase.clicked ? seek(phase) : false"
          />

          <svg viewBox="0 0 100 2" xmlns="http://www.w3.org/2000/svg" v-else>
            <line
              class="progress__bg"
              x1="0" y1="0" x2="100" y2="0"
            />
            <line
              class="progress__fill"
              x1="0" y1="0" x2="100" y2="0"
              :style="{ transform: `scaleX(${phase.progress})`, transformOring: '0% 50%' }"
            />
          </svg>
        </li>
      </ul>
    </div>

    <button class="switch-mode" :class="{ 'switch-mode--rotate' : video.mode === 'sing' }" @click="switchMode">
      <span class="switch-mode__bg" />
      <div class="switch-mode__content">
        <p class="switch-mode__text switch-mode__text--small">Change</p>
        <img class="switch-mode__image" src="/assets/img/misc/mode.png" alt="Mode icon text">
      </div>
    </button>
  </main>
</template>

<script>
import { gsap } from 'gsap';
import { SplitText } from '@/assets/libs/SplitText';
import VideoIcon from '@/components/VideoIcon.vue';
import VideoKaraoke from '@/scripts/VideoKaraoke';
import video from '@/assets/data/video.json';
import switchMode from '@/scripts/transitions/switchMode';
import raf from '@/scripts/raf';
import audio from '@/scripts/audio';
import { isTouchDevice } from '@/scripts/utils';

export default {
  name: 'Home',

  components: {
    VideoIcon,
  },

  data() {
    return {
      video,
      isPlaying: false,
      modeInTransition: false,
      isTouchDevice: isTouchDevice(),
    };
  },

  mounted() {
    this.$refs.video.load();

    this.videoKaraoke = new VideoKaraoke(this.$el, this.video);
    this.addEvents();

    raf.add(() => this.onTimeUpdate(), 'videoRenderer');
  },

  methods: {
    enter() {
      const callback = () => {
        if (!this.isTouchDevice) this.$refs.video.volume = 0.1;
        this.togglePlay();

        this.tl = null;
      };
      gsap.set(this.$refs.video, { autoAlpha: 0 });
      gsap.set('.controls__item--progress', { scaleX: 0, transformOrigin: (i) => i < 3 ? '100% 50%' : '0% 50%' }); // eslint-disable-line
      gsap.set('.controls__item--icon', { scale: 0, transformOrigin: '50% 50%' });
      gsap.set('.lyrics__container', { y: 80, autoAlpha: 0 });
      gsap.set('.switch-mode', { scale: 0, pointerEvents: 'none' });

      this.tl = gsap.timeline();
      this.tl.addLabel('start', !this.firstIntroDone ? 4 : 0)
        .to(this.$refs.video, {
          autoAlpha: 1,
          ease: 'coor',
          duration: 2.5,
        }, 0.1)
        .to('.controls__item--progress', {
          scaleX: 1,
          transformOrigin: (i) => i < 3 ? '100% 50%' : '0% 50%', // eslint-disable-line
          ease: 'coor',
          duration: 1.25,
          stagger: {
            amount: 0.2,
            from: 'center',
            axis: 'x',
            grid: [1, 6],
          },
        }, 'start+=1')
        .to('.controls__item--icon', {
          scale: 1,
          transformOrigin: '50% 50%',
          ease: 'coor',
          duration: 1.25,
          stagger: {
            amount: 0.2,
            from: 'center',
            axis: 'x',
            grid: [1, 5],
          },
        }, 'start+=1.25')
        .to('.switch-mode', {
          scale: 1,
          pointerEvents: 'auto',
          ease: 'coor',
          duration: 1.25,
          onComplete: () => {
            if (this.firstIntroDone) callback();
          },
        }, 'start+=2');

      if (!this.firstIntroDone) {
        gsap.set(this.$refs.intro, { autoAlpha: 0 });
        const splitEl = this.$refs.intro.querySelector('p');
        const split = new SplitText(splitEl, { type: 'lines, words', wordsClass: 'content__words', linesClass: 'split-line' });
        gsap.set(split.words, { autoAlpha: 0.5 });
        gsap.set(split.lines, { autoAlpha: 0, y: 60 });

        this.tl.set(this.$refs.intro, { autoAlpha: 1 }, 0)
          .to(split.lines, {
            y: 0,
            autoAlpha: 1,
            ease: 'coor',
            duration: 1,
            stagger: 0.8,
          }, 0)
          .to(split.words, {
            autoAlpha: 1,
            duration: 1.5,
            stagger: 0.08,
          }, 0.1)
          .to(split.lines, {
            y: -40,
            autoAlpha: 0,
            ease: 'coor',
            duration: 1,
            stagger: 0.1,
            onComplete: () => {
              this.$store.commit('REMOVE_INTRO');
              this.$refs.intro.remove();

              callback();
            },
          }, window.innerWidth >= 768 ? 5.8 : 6.8);
      }

      return this.tl;
    },
    addEvents() {
      this.$refs.video.addEventListener('loadedmetadata', this.setLastFrame);
      this.handlerEnded = () => {
        this.isPlaying = false;
        this.$refs.video.currentTime = 0;

        this.togglePlay();
      };
      this.$refs.video.addEventListener('ended', this.handlerEnded);

      if (this.isTouchDevice) {
        this.handlerPlay = () => audio.play();
        this.handlerPause = () => audio.pause();
        this.handlerSeeked = () => audio.seek(this.$refs.video.currentTime);

        this.$refs.video.addEventListener('play', this.handlerPlay);
        this.$refs.video.addEventListener('pause', this.handlerPause);
        this.$refs.video.addEventListener('seeked', this.handlerSeeked);
      }
    },
    showPhase(phase) {
      phase.clicked = true; // eslint-disable-line
    },
    togglePlay() {
      if (!this.isPlaying) {
        this.isPlaying = true;
        this.$refs.video.play();
      } else {
        this.isPlaying = false;
        this.$refs.video.pause();
      }
    },
    onTimeUpdate() {
      if (!this.$refs.video) return;

      this.videoKaraoke.updateTimelines(this.$refs.video.currentTime);
    },
    setLastFrame() {
      this.videoKaraoke.lyricsTl.add(() => console.log('song ended'), this.$refs.video.duration);
      this.videoKaraoke.translateMobileTl.add(() => console.log('song ended'), this.$refs.video.duration);
      this.videoKaraoke.logosTl.add(() => console.log('song ended'), this.$refs.video.duration);
    },
    seek(phase) {
      if (phase.index === this.video.currentPhase) return;

      this.$refs.video.currentTime = phase.start;
    },
    switchMode() {
      if (this.modeInTransition) return;
      this.modeInTransition = true;

      this.video.mode = this.video.mode === 'cards' ? 'sing' : 'cards';

      switchMode(this.video.mode).then(() => { this.modeInTransition = false; });
    },
  },

  beforeDestroy() {
    if (this.isPlaying) this.togglePlay();

    if (this.isTouchDevice) {
      this.$refs.video.removeEventListener('play', this.handlerPlay);
      this.$refs.video.removeEventListener('pause', this.handlerPause);
      this.$refs.video.addEventListener('seeked', this.handlerSeeked);

      audio.stop();
    }

    raf.remove('videoRenderer');

    if (this.tl) this.tl.kill();

    this.videoKaraoke.destroy();
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    object-fit: cover;
  }

  &__performed {
    position: absolute;
    top: 15vh;
    left: 50%;
    min-width: 150px;
    width: 10vw;

    transform: translateX(-50%);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25vw;
    min-width: 200px;

    transform: translate(-50%, -50%);

    font-family: 'Tropiline';
    color: #e0dedc;
    text-align: center;
    font-size: 16px;

    pointer-events: none;

    @include mq(ds) {
      font-size: rem(20px);
      font-size: calc(20px * var(--rw));
    }

    &--hiring {
      width: 100vw;

      h1,
      p {
        font-family: 'Tropiline';
        font-weight: normal;
      }

      h1 {
        font-size: rem(45px);
        font-size: calc(45px * var(--rw));
        line-height: 1;

        @include mq(ipadP) {
          font-size: rem(100px);
          font-size: calc(100px * var(--rw));
        }
      }

      p {
        font-size: rem(25px);
        font-size: calc(25px * var(--rw));
        line-height: 1;

        @include mq(ipadP) {
          font-size: rem(50px);
          font-size: calc(50px * var(--rw));
        }
      }
    }

    &--performed p {
      margin-bottom: 50px;

      @include mq(ds) {
        margin-bottom: 3.5vh;
      }
    }

    &--supported {
      p {
        margin: 100px 0 35px;

        @include mq(ds) {
          margin: 10vh 0 3.5vh;
        }
      }

      img:last-child {
        width: 65%;
      }
    }

    &--syndrome {
      width: 15vw;
    }
  }

  &__intro {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-family: 'Tropiline';
      font-size: max(calc(16px * var(--rw)), 15px);
      line-height: 2;
      color: $color-bg;

      max-width: calc(100vw - 50px);

      @include mq(ipadP) {
        max-width: 75vw;
        font-size: max(calc(21px * var(--rw)), 18px);
      }

      @include mq(ipadL) {
        max-width: 48vw;
      }
    }
  }

  .lyrics {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 100%;

    color: $color-bg;
    transform: translate(-50%, -50%);
    text-align: center;

    @include mq(ipadP) {
      bottom: 75px;
    }

    p {
      display: block;
      width: 100%;
      margin: 0;
      font-family: 'Tropiline';

      font-size: rem(24px);
      font-size: calc(24px * var(--rw));

      @include mq($until: ds) {
        font-size: 18px;
      }

      &:not(:nth-child(2)) {
        position: absolute;
        top: 0;
      }
    }

    span {
      opacity: 0.3;
    }
  }

  .video__controls {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding-bottom: 50px;

    overflow: hidden;
    pointer-events: none;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .controls__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .controls__item {
      pointer-events: auto;

      &--progress {
        width: calc(50vw - 65px);
        flex: 0 0 calc(50vw - 65px);

        .progress__bg {
          stroke: rgba($white, 0.3);
          stroke-width: 1px;
        }
        .progress__fill {
          stroke: rgba($white, 1);
          stroke-width: 1px;
        }

        @include mq(ipadP) {
          width: calc((100vw / 6) - 80px);
          flex: 0 0 calc((100vw / 6) - 80px);
        }
      }
    }
  }

  .switch-mode {
    position: absolute;
    right: 4vw;
    bottom: 130px;
    width: 6vw;
    min-width: 65px;
    height: 6vw;
    min-height: 65px;

    cursor: pointer;

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;

      background-color: $black;
      transition: transform 0.5s cubic-bezier(.82,.89,.31,1.6);
    }

    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
      position: relative;
      color: $white;
      transform: rotate(20deg);
      transition: transform 1s $ease-out;
    }

    &__image {
      position: relative;
      width: 70%;

      transform: translateY(40%);
    }

    &__text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      transform: translateY(-70%);
      font-size: 14px;
      text-transform: capitalize;
      font-family: 'Tropiline';

      @include mq(ipadP) {
        font-size: rem(16px);
        font-size: calc(16px * var(--rw));
      }
    }

    @include mq($and: $is-not-touch-device) {
      &:hover {
        .switch-mode__content {
          transform: rotate(-20deg);
        }

        .switch-mode__bg {
          transform: scale(1.15);
        }
      }
    }

    @include mq($and: $is-touch-device) {
      &.switch-mode--rotate .switch-mode__content {
        transform: rotate(-20deg);
      }
    }
  }
}
</style>

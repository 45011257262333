<template>
  <header class="o-header header" :class="{ 'header--burger-open' : $store.state.showMenu }" data-theme="">
    <div class="header__item">
      <router-link class="header__link" to="/hire">
        <AppButton class="header__button" :content="contentButton" :isAnimated="false"/>
      </router-link>

      <router-link
        class="header__link header__link--dsk"
        to="/share"
        @mouseenter.native="enterHover"
        @mouseleave.native="leaveHover"
        data-enter="voice"
      >
        <span class="hover__container">
          <span class="hover__text" data-hover="first">Share</span>
          <span class="hover__text hover__text--fake" data-hover="second">Share</span>
        </span>
      </router-link>
    </div>

    <div class="header__item">
      <router-link
        class="header__link header__link--dsk"
        to="/about"
        @mouseenter.native="enterHover"
        @mouseleave.native="leaveHover"
        data-enter="voice"
      >
        <span class="hover__container">
          <span class="hover__text" data-hover="first">About</span>
          <span class="hover__text hover__text--fake" data-hover="second">About</span>
        </span>
      </router-link>

      <button
        class="header__burger burger"
        :class="{ 'burger--open' : $store.state.showMenu }"
        @click="$store.commit('TOGGLE_MENU', 'fromButton')"
      >
        <div class="burger__container">
          <span class="burger__item"/>
          <span class="burger__item"/>
          <span class="burger__item"/>
          <span class="burger__item"/>
          <span class="burger__item"/>
        </div>
      </button>
    </div>
  </header>
</template>

<script>
import { gsap } from 'gsap';
import AppButton from '@/components/AppButton.vue';
import { setHoverVoice, hoverVoice } from '@/scripts/animations/hoverVoice';
import { fadeSet, fadeIn } from '@/scripts/animations/fade';
import { buttonSet, buttonIn } from '@/scripts/animations/button';

export default {
  name: 'AppHeader',

  components: {
    AppButton,
  },

  computed: {
    contentButton() {
      return window.innerWidth >= 768 ? 'Hire now' : 'Hire';
    },
  },

  mounted() {
    this.hide();

    this.$el.setAttribute('data-theme', this.$route.name === 'Share' ? 'light' : 'dark');
    setHoverVoice(this.$el);
  },

  methods: {
    changeTheme(theme = 'dark') {
      this.$el.setAttribute('data-theme', theme);
      this.$eventHub.$emit('changeTheme', theme);
    },
    enterHover(e) {
      hoverVoice(e.target, 'enter');
    },
    leaveHover(e) {
      hoverVoice(e.target, 'leave');
    },
    hide() {
      gsap.set(this.$el, { autoAlpha: 0 });
    },
    enter() {
      const button = this.$el.querySelector('.o-header .header__button');
      const voices = [...this.$el.querySelectorAll('[data-enter="voice"]')];
      const burger = this.$el.querySelector('.o-header .header__burger');
      const tl = gsap.timeline();

      buttonSet(button);
      fadeSet(voices, { y: 30 });
      gsap.set(burger, { scaleX: 0, transformOrigin: '100% 50%' });

      tl.addLabel('start')
        .set(this.$el, { autoAlpha: 1 }, 'start')
        .add(fadeIn(voices, 0.25), 'start+=0.25')
        .to(burger, {
          scaleX: 1,
          transformOrigin: '100% 50%',
        }, 'start+=0.5')
        .add(buttonIn(button), 'start+=0.5');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 3.7vh calc(6.4vw - 10px) 3.7vh 6.4vw;
  padding: calc(var(--vh, 1vh) * 3.7) calc(6.4vw - 10px) calc(var(--vh, 1vh) * 3.7) 6.4vw;

  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.05em;

  display: flex;
  align-items: center;
  justify-content: space-between;

  pointer-events: none;
  z-index: 15;

  @include mq(ipadP) {
    padding: 3.7vh 6.4vw;
    padding: calc(var(--vh, 1vh) * 3.7) 6.4vw;
  }

  .header__item {
    display: flex;
    align-items: center;

    & > * {
      display: inline-block;
      position: relative;

      pointer-events: all;
      cursor: pointer;

      &:nth-child(2) {
        margin-left: 2vw;
      }
    }

    @include mq($until: ipadP) {
      &:nth-child(1) {
        margin-left: calc(-6.4vw - 20px);

        &::v-deep .button__content {
          margin-left: 2px;
          transform: translateX(5px);
        }
      }
    }
  }

  .burger {
    position: relative;
    padding: 15px 10px;

    &__container {
      position: relative;
      width: 24px;
      height: 11px;
    }

    &__item {
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;

      background-color: $black;
      transform-origin: 100% 50%;
      transition: transform 0.5s $ease-out;

      &:nth-child(1) {
        top: 0;
        transition-delay: 0.45s;
      }
      &:nth-child(2) {
        top: 5px;
        transform: scaleX(0.7);
        transition-delay: 0.55s;
      }
      &:nth-child(3) {
        top: 10px;
        transition-delay: 0.65s;
      }
      &:nth-child(4) {
        top: 5px;
        transform: scaleX(0) scaleY(0) rotate(35deg);
        transform-origin: 50% 50%;
      }
      &:nth-child(5) {
        top: 5px;
        transform: scaleX(0) scaleY(0) rotate(-35deg);
        transform-origin: 50% 50%;
      }
    }

    &.burger--open {
      .burger__item {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          transform: scaleX(0);
        }
        &:nth-child(1) {
          transition-delay: 0s;
        }
        &:nth-child(2) {
          transition-delay: 0.1s;
        }
        &:nth-child(3) {
          transition-delay: 0.2s;
        }
        &:nth-child(4) {
          transform: scaleX(0.7) scaleY(1) rotate(35deg);
          transition-delay: 0.45s;
        }
        &:nth-child(5) {
          transform: scaleX(0.7) scaleY(1) rotate(-35deg);
          transition-delay: 0.45s;
        }
      }
    }
  }

  @include mq($until: ipadP) {
    .header__link--dsk {
      display: none;
    }
  }

  @include mq(ipadP) {
    top: 5vh;
    top: calc(var(--vh, 1vh) * 5);
    padding: 0 4vw;

    .burger {
      display: none;
    }

    .header__link--dsk {
      &::before {
        content: '';
        position: absolute;
        bottom: -2px;
        width: 100%;
        height: 1px;

        transform-origin: 100% 50%;
        transform: scaleX(0);
        transition: transform 0.3s $ease-out 0.5s;
      }

      &.router-link-exact-active::before {
        transform-origin: 0 50%;
        transform: scaleX(1);
      }
    }

    &[data-theme="light"] .header__link--dsk::before {
      background-color: $color-bg;
    }
    &[data-theme="dark"] .header__link--dsk::before {
      background-color: $color-text;
    }
  }

  @include mq(d) {
    font-size: 14px;
    font-size: rem(14px);
    font-size: min(calc(14px * var(--rw)), 18px);
  }

  .header__button::v-deep {
    &.button--black {
      .button__bg {
        transition: background-color 0.35s $ease-out;
      }

      .button__content {
        transition: color 0.35s $ease-out;
      }
    }

    @include mq($until: ipadP) {
      font-size: 12px;
    }
  }

  .header__link.router-link-exact-active .header__button {
    pointer-events: none !important;
  }

  &[data-theme="light"]:not(.header--burger-open) {
    a {
      color: $color-bg;
    }

    .header__button::v-deep {
      &.button--black {
        .button__bg {
          background-color: $color-bg;
        }

        .button__content {
          color: $black;
        }
      }
    }

    .burger {
      &__item {
        background-color: $color-bg;
      }

      &.burger--open .burger__item {
        background-color: $black;
      }
    }
  }
}
</style>

import { gsap } from 'gsap';
import { SplitText } from '@/assets/libs/SplitText';
import { isTouchDevice } from '@/scripts/utils';

export function setHoverVoice(el) {
  if (!isTouchDevice()) {
    el.querySelectorAll('[data-hover="first"]').forEach((hoverEl) => {
      if (hoverEl._isSplit) return; // eslint-disable-line
      new SplitText(hoverEl, { type: 'chars', charsClass: 'hover__chars' })// eslint-disable-line
    });
    el.querySelectorAll('[data-hover="second"]').forEach((hoverEl) => {
      if (hoverEl._isSplit) return; // eslint-disable-line
      const split = new SplitText(hoverEl, { type: 'chars', charsClass: 'hover__chars' });
      gsap.set(split.chars, { yPercent: 50, rotateX: 90, autoAlpha: 0 });
    });
  }
}

export function hoverVoice(el, dir) {
  if (isTouchDevice()) return;

  const outChars = [...el.querySelectorAll(`[data-hover="${dir === 'enter' ? 'first' : 'second'}"] .hover__chars`)];
  const inChars = [...el.querySelectorAll(`[data-hover="${dir === 'enter' ? 'second' : 'first'}"] .hover__chars`)];

  gsap.killTweensOf(outChars);
  gsap.killTweensOf(inChars);

  const tl = gsap.timeline();
  tl.addLabel('start')
    .to(outChars, {
      yPercent: dir === 'enter' ? -50 : 50,
      rotateX: '90deg',
      autoAlpha: 0,
      duration: 0.4,
      ease: 'power3.easeInOut',
      stagger: 0.05,
    }, 'start')
    .to(inChars, {
      autoAlpha: 1,
      yPercent: 0,
      rotateX: 0,
      duration: 0.4,
      ease: 'power3.easeInOut',
      stagger: 0.05,
    }, 'start');

  return tl; // eslint-disable-line
}

import { gsap } from 'gsap';

export function circularButtonSet(el) {
  const circle = el.querySelector('circle');
  const { type } = el.dataset;

  if (type === 'add') {
    const plus = el.querySelector('g');

    gsap.set(plus, { scale: 0, transformOrigin: '50% 50%' });
  } else {
    const rect = el.querySelector('rect');
    const cap = el.querySelector('polygon');

    gsap.set(rect, { scaleY: 0, y: type === 'down' ? -10 : 10, transformOrigin: type === 'down' ? '50% 0' : '50% 100%' });
    gsap.set(cap, {
      y: type === 'down' ? -10 : 10,
      clipPath: type === 'down' ? 'inset(100% -0.01% -0.02% -0.03%)' : 'inset(-0.01% -0.02% 100% -0.03%)',
    });
  }

  gsap.set(circle, {
    scale: 0.25,
    autoAlpha: 0,
    transformOrigin: '50% 50%',
  });
}

export function circularButtonIn(el) {
  const circle = el.querySelector('circle');
  const { type } = el.dataset;
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to(circle, {
      scale: 1,
      autoAlpha: 1,
      duration: 2,
      ease: 'coor',
    });

  if (type === 'add') {
    const plus = el.querySelector('g');

    tl.to(plus, {
      scale: 1,
      duration: 2,
      ease: 'coor',
      clearProps: 'all',
    }, 'start+=0.5');
  } else {
    const rect = el.querySelector('rect');
    const cap = el.querySelector('polygon');

    tl.to(rect, {
      y: 0,
      scaleY: 1,
      duration: 2,
      ease: 'coor',
      clearProps: 'all',
    }, 'start+=0.5')
      .to(cap, {
        y: 0,
        duration: 2,
        ease: 'coor',
        clearProps: 'all',
      }, 'start+=0.5')
      .to(cap, {
        clipPath: 'inset(0% -0.01% -0.02% -0.03%)',
        duration: 1.5,
        ease: 'coor',
        clearProps: 'all',
      }, 'start+=1');
  }

  return tl;
}

<template>
  <div class="app">
    <AppLogo />

    <AppHeader ref="header" />

    <transition @enter="enter" @leave="leave" mode="in-out">
      <router-view :key="$route.fullPath" ref="main" />
    </transition>

    <CanvasForeground ref="canvasForeground" />

    <Navigator />

    <HireModal />

    <transition @enter="enterLoader" @leave="leaveLoader" appear>
      <Loader v-if="$store.state.showLoader" />
    </transition>

    <AppRotateDevice />

    <AppGrain />
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { SplitText } from '@/assets/libs/SplitText';
import debounce from 'lodash/debounce';
import { CustomEase } from '@/assets/libs/CustomEase';
import { InertiaPlugin } from '@/assets/libs/InertiaPlugin';

import AppLogo from '@/components/AppLogo.vue';
import AppHeader from '@/components/AppHeader.vue';
import Navigator from '@/components/Navigator.vue';
import HireModal from '@/components/HireModal.vue';
import CanvasForeground from '@/components/CanvasForeground.vue';
import Loader from '@/components/Loader.vue';
import AppGrain from '@/components/AppGrain.vue';
import AppRotateDevice from '@/components/AppRotateDevice.vue';
import medusa from '@/scripts/medusa';
import raf from '@/scripts/raf';

gsap.registerPlugin(CustomEase, Draggable, InertiaPlugin);
CustomEase.create('coor', '.15,.6,.1,1');

export default {
  name: 'App',

  components: {
    AppLogo,
    AppHeader,
    HireModal,
    Navigator,
    CanvasForeground,
    Loader,
    AppGrain,
    AppRotateDevice,
  },

  data() {
    return {
      firstEnter: this.$route.name !== 'Home',
      prevContainer: null,
    };
  },

  mounted() {
    medusa.init();

    raf.start(true);

    this.resize();
    window.addEventListener('resize', debounce(() => this.resize(), 100));
  },

  methods: {
    resize() {
      this.$eventHub.$emit('windowResize');

      const rw = window.innerWidth / (window.innerWidth < 768 ? 375 : 1440);
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--rw', rw);
    },
    enter(el, done) {
      const menuVoices = [...this.$refs.header.$el.querySelectorAll('.header__item a'), ...this.$refs.header.$el.querySelectorAll('.header__item button')];
      if (!this.firstEnter) gsap.set(menuVoices, { pointerEvents: 'none' });
      if (!this.firstEnter) gsap.set(this.$el, { pointerEvents: 'none' });

      if (!this.firstEnter) medusa.removePrevNodesFromAll();
      const [...elToAnimate] = el.querySelectorAll('[data-animation]');

      medusa.recalculateObservedNodes(elToAnimate);
      if (!this.firstEnter) gsap.set(this.prevContainer, { zIndex: 0 });
      if (!this.firstEnter) gsap.set(el, { autoAlpha: 0, zIndex: 10 });
      if (this.firstEnter) this.firstEnter = false;
      this.prevContainer = el;

      done();
    },
    leave(el, done) {
      if (this.$store.state.showMenu) this.$store.commit('TOGGLE_MENU', 'fromTransition');

      const tl = gsap.timeline();
      const callback = () => {
        done();
        gsap.set(this.$refs.canvasForeground.$el, { opacity: 0 });
        gsap.set(this.$refs.main.$el, { zIndex: 0 });
      };

      tl.add('start')
        .add(() => {
          this.$refs.canvasForeground.stage.transition(this.$refs.main.$el).then(callback);
        }, 'start')
        .to(el, {
          y: window.innerHeight * -0.25,
          zIndex: 0,
          duration: 1.55,
          ease: 'coor',
        }, 'start+=0.55')
        .set(this.$refs.main.$el, { autoAlpha: 1, zIndex: 10 }, 'start+=0.8')
        .add(this.$refs.main.enter(), 'start+=0.85')
        .add(() => { this.$el.scrollTop = 0; }, 'start+=0.9')
        .set(this.$refs.canvasForeground.$el, { opacity: 1 });

      if (this.$route.name === 'Share' || this.$route.name === 'Home') {
        tl.add(() => this.$refs.header.changeTheme('light'), 0.6);
      } else if (this.$refs.header.$el.dataset.theme === 'light') {
        tl.add(() => this.$refs.header.changeTheme('dark'), 0.6);
      }
    },
    enterLoader(el, done) {
      this.$el.scrollTop = 0;
      gsap.set(this.$el, { autoAlpha: 0 });

      document.fonts.ready.then(() => {
        const split = new SplitText('.loader__cookie', { type: 'lines, words', linesClass: 'split-line' });
        gsap.set('.c-loader .drag__dot', { clipPath: 'inset(-0.01% 50.02% 0 50.01%)' });
        gsap.set('.c-logo .logo__container', {
          y: '20vh',
          scale: window.innerWidth >= 768 ? 2.8 : 1.85,
          pointerEvents: 'none',
        });
        gsap.set('.c-logo .logo__image', { transformOrigin: 'bottom' });
        gsap.set(this.$el, { autoAlpha: 1 });

        const tl = gsap.timeline({ onComplete: done });

        tl.addLabel('start')
          .from('.c-logo .logo__image:first-child', {
            y: 40,
            scale: 0,
            autoAlpha: 0,
            duration: 2,
            ease: 'coor',
          }, 'start')
          .from('.c-logo .logo__image:last-child', {
            y: 25,
            scale: 0,
            autoAlpha: 0,
            duration: 1.85,
            ease: 'coor',
          }, 'start+=0.15')
          .from('.c-loader .drag__hand', { scale: 0, ease: 'coor', duration: 1.5 }, 'start+=0.35')
          .to('.c-loader .drag__dot', { clipPath: 'inset(-0.01% -0.02% 0 -0.03%)' }, 'start+=0.35')
          .from('.c-loader .loader__content p', { y: 40, autoAlpha: 0 }, 'start+=0.7')
          .from(split.lines, {
            y: 40,
            autoAlpha: 0,
            stagger: 0.13,
            ease: 'coor',
            duration: 1.3,
          }, 'start+=1.0');
      });
    },
    leaveLoader(el, done) {
      gsap.set(this.$refs.main.$el, { autoAlpha: 0 });
      const tl = gsap.timeline({ onComplete: done });
      const callback = () => {
        gsap.set('.c-loader', { autoAlpha: 0 });
        gsap.set(this.$refs.main.$el, { zIndex: 0 });
      };

      tl.addLabel('start')
        .set('.c-loader', { zIndex: 0 }, 'start')
        .to('.c-loader .loader__content', {
          y: -40,
          autoAlpha: 0,
          ease: 'coor',
          duration: 1.3,
        }, 'start+=0.35')
        .to('.c-loader .split-line', {
          y: -40,
          autoAlpha: 0,
          stagger: 0.13,
          ease: 'coor',
          duration: 1.3,
        }, 'start+=0.7')
        .add(() => this.$refs.canvasForeground.stage.transition(this.$refs.main.$el).then(callback), 'start+=0.6')
        .to('.c-logo .logo__container', {
          y: 0,
          scale: 1,
          duration: 1.5,
          ease: 'coor',
          clearProps: 'all',
        }, 'start+=0.8')
        .set(this.$refs.main.$el, { autoAlpha: 1, zIndex: 10 }, 'start+=0.8')
        .add(() => {
          if (this.$route.name === 'Share' || this.$route.name === 'Home') {
            this.$refs.header.changeTheme('light');
          } else if (this.$refs.header.$el.dataset.theme === 'light') {
            this.$refs.header.changeTheme('dark');
          }
        }, 'start+=0.8')
        .add(this.$refs.main.enter(), 'start+=1.15')
        .add(() => this.$refs.header.enter(), 'start+=0.8');
    },
  },
};
</script>

<style lang="scss">
@import "./scss/common.scss";
@import "./scss/base.scss";

.app {
  @extend %full;

  overflow-y: scroll;
  // scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  > main {
    position: relative;

    &.share,
    &.home {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @include mq(ipadP) {
      position: absolute;
      top: 0;
      left: 0;

      &.share,
      &.home,
      &.hire {
        position: fixed;
        right: 0;
        bottom: 0;
      }
    }
  }

  .router-link-exact-active {
    pointer-events: none !important;
  }
}

.c-page-section {
  position: relative;

  & + .c-page-section {
    margin-top: 12vh;
    margin-top: calc(var(--vh, 1vh) * 12);

    @include mq(ipadP) {
      margin-top: 30vh;
      margin-top: calc(var(--vh, 1vh) * 30);
    }
  }

  @extend %grid-column;
}

.split-line {
  display: block;
  white-space: nowrap;
}

.u-split-container {
  display: block;
  overflow: hidden;
  padding-top: 2px;
  padding-right: 2px;
}

.hover__container {
  display: block;
  position: relative;

  .hover__text.hover__text--fake {
    position: absolute;
    top: 0;
    left: 0;

    @include mq($and: $is-touch-device) {
      display: none;
    }
  }
}
</style>

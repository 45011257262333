<template>
  <div class="c-loader loader">
    <div class="loader__content">
      <div class="loader__drag" ref="draggableArea">
        <div class="drag__container">
          <div
            class="drag__hand drag__hand--left"
            @mousedown="target = 0"
            @touchstart="target = 0"
          >
            <img src="/assets/svg/hand-loader-left.svg" alt="left hand icon">
          </div>
          <span class="drag__dot" />
          <div
            class="drag__hand drag__hand--right"
            @mousedown="target = 1"
            @touchstart="target = 1"
          >
            <img src="/assets/svg/hand-loader-right.svg" alt="right hand icon">
          </div>
        </div>
      </div>

      <p>HOLD & DRAG TO START</p>
    </div>

    <div class="loader__cookie">
      By entering this website, you consent to the use of cookies in<br>accordance with our <a href="https://www.iubenda.com/privacy-policy/19035635/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>.
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { isTouchDevice } from '@/scripts/utils';
import audio from '@/scripts/audio';

export default {
  name: 'Loader',

  data() {
    return {
      target: 1,
      isFirstInteraction: true,
      isTouchDevice: isTouchDevice(),
    };
  },

  mounted() {
    const hand = this.$el.querySelector('.drag__hand');
    if (this.isTouchDevice) {
      audio.init();
      audio.load();
    }

    this.drags = Draggable.create('.drag__hand', {
      type: 'x',
      bounds: this.$refs.draggableArea,
      inertia: true,
      throwResistance: 5000,
      maxDuration: 0.8,
      minDuration: 0.4,
      liveSnap: (value) => { // eslint-disable-line
        const max = this.drags[this.target][this.target === 1 ? 'minX' : 'maxX'] / 2;
        const valueDef = this.target === 1 ? Math.max(value, max) : Math.min(value, max);
        const progress = Math.min(50 * (this.drags[this.target].x / (max - (hand.getBoundingClientRect().width / 2))), 50);

        gsap.set('.drag__dot', { webkitClipPath: `inset(-0.01% ${progress}% 0 ${progress + 0.001}%)`, clipPath: `inset(-0.01% ${progress}% 0 ${progress + 0.001}%)` });
        gsap.set(`.drag__hand--${this.target === 1 ? 'left' : 'right'}`, { x: -1 * valueDef });

        if ((this.target === 0 && this.drags[this.target].x >= max) || (this.target === 1 && this.drags[this.target].x <= max)) {
          if (!this.isTouchDevice) this.drags.forEach((drag) => drag.kill());
          this.$store.commit('REMOVE_LOADER');
        }

        return valueDef;
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  text-align: center;
  color: $black;
  background-color: $sand;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  z-index: 20;

  &__content {
    position: relative;
    margin: 19.7vh 0 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;

    p {
      display: block;
      position: relative;
      margin-top: 6.15vh;

      @include fluid-type(1440px, 3840px, 14px, 38px, 15px, 38px);
    }

    @include mq(ipadP) {
      margin: 35vh 0 17.3vh;

      p {
        margin-top: 5.6vh;
      }
    }
  }

  &__drag {
    .drag__container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .drag__hand {
      width: 50px;

      cursor: pointer;
    }

    .drag__dot {
      display: inline-block;
      width: 70px;

      border-bottom: 1px dashed $black;
      opacity: 0.5;
    }
  }

  &__cookie {
    position: absolute;
    bottom: 6.15vh;
    left: 0;
    width: 100%;

    font-size: 12px;
    text-transform: uppercase;
    color: rgba($black, 0.75);

    a {
      display: inline-block;
      position: relative;
      color: $black;

      @include hover-underline(-2px, 1px, rgba($black, 0.75));
    }

    @include mq(ipadP) {
      letter-spacing: 1px;
      @include fluid-type(1440px, 3840px, 10px, 27px, 14px, 32px);

      a {
        @include hover-underline(-2px, 1px, $black);
      }
    }
  }
}
</style>

import { gsap } from 'gsap';
import eventHub from '@/scripts/eventHub';

export default class VideoKaraoke {
  constructor(ctx, data) {
    this.ctx = ctx;

    this.game = data;
    this.progressTl = null;
    this.translateMobileTl = null;
    this.lyricsTl = null;

    this.createTimelines();

    this.handlerResize = () => this.resize();
    eventHub.$on('windowResize', this.handlerResize);
  }

  createTimelines() {
    let start = 0;
    this.translateCont = 0;
    this.progressTl = gsap.timeline({ paused: true });
    this.translateMobileTl = gsap.timeline({ paused: true });
    this.lyricsTl = gsap.timeline({ paused: true });
    this.logosTl = gsap.timeline({ paused: true });

    this.game.phases.forEach((phase, i) => {
      phase.index = i; // eslint-disable-line
      phase.start = start; // eslint-disable-line
      start += phase.duration;

      this.progressTl
        .set(phase, { isActive: true }, phase.start)
        .set(this.game, { currentPhase: i }, phase.start)
        .to(phase, {
          progress: 1,
          duration: phase.duration,
          ease: 'linear',
        }, phase.start)
        .set(phase, { isActive: false }, start);

      if (i > 0 && i < (this.game.phases.length - 1) && i % 2 === 0) {
        this.translateMobileTl.to('.video__controls .controls__container', {
          x: () => window.innerWidth >= 768 ? 0 : -1 * this.calcTranslate(), // eslint-disable-line
          duration: 2,
          ease: 'coor',
        }, `${phase.start + 1}`);
      }
    });

    this.game.lyrics.forEach((line, indexLine) => {
      let tweenConfig = {};

      switch (true) {
        case indexLine === 0:
          tweenConfig = { yPercent: -100 };
          break;

        case indexLine === 1:
          tweenConfig = { yPercent: 0 };
          break;

        case indexLine === 2:
          tweenConfig = { yPercent: 100 };
          break;

        default:
          tweenConfig = { yPercent: 200, autoAlpha: 0 };
          break;
      }

      gsap.set(`[data-line="${indexLine}"]`, tweenConfig);

      let prevTime = 0;

      line.forEach((word, indexWord) => {
        this.lyricsTl.to(`[data-line="${indexLine}"] [data-word="${indexWord}"]`, { autoAlpha: 1, ease: 'coor', duration: 0.35 }, word.start);

        if (indexWord === line.length - 1) prevTime = word.start + 0.35;
      });

      if (indexLine > 0 && indexLine < this.game.lyrics.length - 2) {
        this.lyricsTl.to(`[data-line="${indexLine - 1}"]`, {
          yPercent: -200,
          autoAlpha: 0,
          duration: 1,
          ease: 'coor',
        }, prevTime)
          .to(`[data-line="${indexLine}"]`, {
            yPercent: -100,
            duration: 1,
            ease: 'coor',
          }, prevTime)
          .to(`[data-line="${indexLine + 1}"]`, {
            yPercent: 0,
            duration: 1,
            ease: 'coor',
          }, prevTime)
          .to(`[data-line="${indexLine + 2}"]`, {
            yPercent: 100,
            autoAlpha: 1,
            duration: 1,
            ease: 'coor',
          }, prevTime);
      } else if (indexLine === this.game.lyrics.length - 1) {
        this.lyricsTl.to('.lyrics__container', {
          autoAlpha: 0,
          duration: 1,
          ease: 'coor',
        }, 92.5);
      }
    });

    this.createLogosAnimations('.home__logo--performed > *', 0);
    this.createLogosAnimations('.home__logo--hiring > *', 92);
    this.createLogosAnimations('.home__logo--supported > *', 96);
    this.createLogosAnimations('.home__logo--syndrome > *', 100);
  }

  createLogosAnimations(selector, start) {
    this.logosTl.fromTo(selector, {
      y: 30,
      autoAlpha: 0,
    }, {
      y: 0,
      autoAlpha: 1,
      duration: 0.5,
      stagger: 0.1,
    }, start)
      .to(selector, {
        y: -30,
        autoAlpha: 0,
        duration: 0.4,
        ease: 'coor',
        stagger: 0.1,
      }, start + 3);
  }

  resize() {
    gsap.set('.video__controls .controls__container', { x: window.innerWidth >= 768 ? 0 : -1 * this.calcTranslate() });
  }

  updateTimelines(progress) {
    if (this.game.mode === 'cards') this.progressTl.seek(progress);
    if (this.game.mode === 'cards') this.translateMobileTl.seek(progress);
    if (this.game.mode === 'sing') this.lyricsTl.seek(progress);
    this.logosTl.seek(progress);
  }

  calcTranslate() {
    const iconWidth = document.querySelector('.video__controls .controls__item.controls__item--icon').getBoundingClientRect().width;

    return ((window.innerWidth + iconWidth) / 2) * (this.game.currentPhase / 2);
  }

  destroy() {
    this.progressTl.seek(0);
    this.translateMobileTl.seek(0);
    this.lyricsTl.seek(0);
    this.logosTl.seek(0);
    this.progressTl.kill();
    this.translateMobileTl.kill();
    this.lyricsTl.kill();
    this.logosTl.kill();
    this.progressTl = null;
    this.translateMobileTl = null;
    this.lyricsTl = null;
    this.logosTl = null;

    eventHub.$off('windowResize', this.handlerResize);
  }
}

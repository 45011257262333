import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import eventHub from './scripts/eventHub';
import { sendEvent } from './scripts/utils';

Vue.config.productionTip = false;
Vue.prototype.$eventHub = eventHub;
Vue.prototype.$sendEvent = sendEvent;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

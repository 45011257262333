<template>
  <button :class="[
    'video__icon',
    'icon',
    isActive && !clicked ? 'icon--active' : clicked ? 'icon--clicked' : false
  ]">
    <svg class="icon__ripple" viewBox="0 0 100 100" fill="none">
      <circle
        v-for="i in 9"
        :key="i"
        cx="50" cy="50" r="35"
      />
    </svg>
    <img class="icon__hand" :src="`/assets/img/misc/${asset}`" alt="icon hand">
  </button>
</template>

<script>
export default {
  name: 'VideoIcon',

  props: {
    icon: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    clicked: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      asset: 'hand-video.png',
    };
  },

  watch: {
    clicked(newVal) {
      if (newVal) {
        this.asset = 'hand-video.gif';
        window.setTimeout(() => { this.asset = 'hand-video-end.png'; }, 1900);
      }
    },
  },

  mounted() {
    if (this.clicked) this.asset = 'hand-video-end.png';
  },
};
</script>

<style lang="scss" scoped>
@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.icon {
  position: relative;
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  margin: 10px 40px 0;

  @include mq(ipadP) {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin: 0 20px;
  }

  &__ripple {
    position: absolute;
    width: 250%;
    height: 250%;
    top: -75%;
    left: -75%;

    opacity: 0;
    transition: opacity 0.65s $ease-out;

    overflow: visible;

    circle {
      stroke: $gold;
      stroke-width: 1px;

      transform-origin: center;
      animation-play-state: paused;

      @for $i from 8 through 1 {
        &:nth-child(#{$i}) {
          animation: ripple 4s infinite $ease-out;
          animation-delay: (500ms * $i);
        }
      }
    }
  }

  &__hand {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    max-width: unset;

    opacity: 0.3;
    transform: translate(-50%, -60%);
    transition: opacity 0.5s $ease-out,
      transform 0.5s $ease-out;

    pointer-events: none;
  }

  &.icon--active,
  &.icon--clicked {
    pointer-events: auto;
    cursor: pointer;

    .icon__hand {
      opacity: 1;
    }
  }

  &.icon--clicked .icon__hand {
    transform: translate(-50%, -50%) scale(1.25);
  }

  &.icon--active {
    .icon__ripple {
      opacity: 1;
    }

    .icon__ripple circle {
      animation-play-state: running;
    }
  }
}
</style>

import { gsap } from 'gsap';
import medusa from '@/scripts/medusa'; // eslint-disable-line

export function bigListSet(el) {
  const linesTitle = [...el.querySelectorAll('.split-line')];

  if (window.innerWidth < 768) {
    const linesTitleCont = [...el.querySelectorAll('.hire-list__item')];

    gsap.set(linesTitleCont, { pointerEvents: 'none' });
  } else {
    gsap.set(el, { pointerEvents: 'none' });
  }

  gsap.set(linesTitle, { yPercent: 120 });
}

export function bigListIn(el) {
  const leftList = el.querySelector('.hire__list--left');
  const rightList = el.querySelector('.hire__list--right');
  const leftListLines = [...leftList.querySelectorAll('.split-line')];
  const rightListLines = [...rightList.querySelectorAll('.split-line')];
  const tl = gsap.timeline();

  tl.addLabel('start');

  if (window.innerWidth < 768) {
    tl.add(() => {
      [...el.querySelectorAll('.hire__list .hire-list__item')].forEach((line) => {
        line.setAttribute('data-animation', 'bigTitle');
        medusa.addNode(line, 'scrollAnimations');
      });
    }, 'start');
  } else {
    tl.to(leftListLines, {
      yPercent: 0,
      duration: 1.2,
      ease: 'coor',
      stagger: 0.15,
    }, 'start')
      .set(el, { pointerEvents: 'auto' }, 'start+=1.5')
      .to(rightListLines, {
        yPercent: 0,
        duration: 1.2,
        ease: 'coor',
        stagger: 0.15,
      }, 'start');
  }

  return tl;
}

import { gsap } from 'gsap';

export function buttonSet(el) {
  const bg = el.querySelector('.button__bg');
  const content = el.querySelector('.button__content');

  gsap.set(bg, { scale: 0, transformOrigin: '50% 50%' });
  gsap.set(content, { yPercent: 50, autoAlpha: 0 });
}

export function buttonIn(el) {
  const bg = el.querySelector('.button__bg');
  const content = el.querySelector('.button__content');
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to(bg, {
      scale: 1,
      duration: 1,
      transformOrigin: '50% 50%',
      ease: 'coor',
      clearProps: 'all',
    }, 'start')
    .to(content, {
      yPercent: 0,
      autoAlpha: 1,
      duration: 1.5,
      ease: 'coor',
      clearProps: 'all',
    }, 'start+=0.35');

  return tl;
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/hire',
    name: 'Hire',
    component: () => import(/* webpackChunkName: "hire" */ '../views/Hire.vue'),
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import(/* webpackChunkName: "share" */ '../views/Share.vue'),
  },
  {
    path: '/*',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  window.ga('send', 'pageview', window.location.pathname);
});

export default router;

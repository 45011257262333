import { gsap } from 'gsap';

export default function hireModal(el, country = '') {
  const bg = el.querySelector('.modal__bg');
  const closeButton = el.querySelector('.modal__close-button');
  const closeIcon = el.querySelector('.modal__close-button svg g');
  const titleFirstLine = el.querySelector('.title__first-line');
  const titleSecondLine = el.querySelector('.title__second-line span');
  const flagCont = el.querySelector('.title__flag-cont');
  const titleCanvas = el.querySelector('.title__canvas');
  const formItems = [...el.querySelectorAll('[data-stagger]')];
  const buttonBg = el.querySelectorAll('.button__bg');
  const buttonText = el.querySelectorAll('.button__content');
  const tl = gsap.timeline({ paused: true, smoothChildTiming: true });

  tl.addLabel('start')
    .fromTo(bg, { scaleX: 0, transformOrigin: '100% 100%' }, {
      scaleX: 1,
      transformOrigin: '100% 100%',
      ease: 'coor',
      duration: 1,
    }, 'start')
    .fromTo(closeButton, { scale: 0 }, {
      scale: 1,
      ease: 'coor',
      duration: 1.5,
    }, 'start+=0.2')
    .fromTo(closeIcon, { scaleX: 0, transformOrigin: '50% 50%' }, {
      scaleX: 1,
      transformOrigin: '50% 50%',
      duration: 1.5,
      ease: 'coor',
    }, 'start+=0.4')
    .fromTo(titleFirstLine, { y: 30, autoAlpha: 0 }, {
      y: 0,
      autoAlpha: 1,
      duration: 1.5,
      ease: 'coor',
    }, 'start+=0.3')
    .fromTo(titleSecondLine, { yPercent: 100 }, {
      yPercent: 0,
      duration: 1.5,
      ease: 'coor',
    }, 'start+=0.45')
    .fromTo(formItems, { y: 80, autoAlpha: 0 }, {
      y: 0,
      autoAlpha: 1,
      duration: 1.5,
      ease: 'coor',
      stagger: 0.15,
    }, 'start+=0.35')
    .fromTo(buttonBg, { scale: 0 }, {
      scale: 1,
      duration: 1.5,
      transformOrigin: '50% 50%',
      ease: 'coor',
      clearProps: 'all',
    }, '-=1')
    .fromTo(buttonText, { yPercent: 50, autoAlpha: 0 }, {
      yPercent: 0,
      autoAlpha: 1,
      duration: 2,
      ease: 'coor',
      clearProps: 'all',
    }, '-=1.3');

  if (country !== '') {
    gsap.set('[data-stagger]:first-child', { display: 'none' });
    gsap.set(flagCont, { display: 'inline-block' });

    tl.fromTo(titleCanvas, { scale: 0, yPercent: -50 }, {
      scale: 1,
      duration: 1.5,
      transformOrigin: '50% 50%',
      ease: 'coor',
    }, 'start+=0.8');
  } else {
    gsap.set('[data-stagger]:first-child', { display: 'block' });
    gsap.set(flagCont, { display: 'none' });
  }

  return tl;
}

<template>
  <div class="c-canvas-foreground" />
</template>

<script>
import CanvasForeground from '@/stage/foreground';

export default {
  name: 'CanvasForeground',

  mounted() {
    this.stage = new CanvasForeground(this.$el);
  },
};
</script>

<style lang="scss" scoped>
.c-canvas-foreground {
  @extend %full;

  pointer-events: none;
  z-index: 5;
}
</style>

<template>
  <div class="app-grain"></div>
</template>

<script>
export default {
  name: 'AppGrain',
};
</script>

<style lang="scss" scoped>
.app-grain {
  @extend %full;

  background-image: url('/assets/img/misc/noise.png');
  opacity: 0.018;

  pointer-events: none;
  z-index: 21;
}
</style>

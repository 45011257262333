<template>
<ul class="c-social-list">
  <li class="social-list__item">
    <a href="https://twitter.com/coordown" target="_blank" rel="noopener noreferrer">
      <img src="/assets/svg/twitter.svg" alt="Twitter icon link">
    </a>
  </li>
  <li class="social-list__item">
    <a href="https://www.instagram.com/coordown/" target="_blank" rel="noopener noreferrer">
      <img src="/assets/svg/instagram.svg" alt="Instagram icon link">
    </a>
  </li>
  <li class="social-list__item">
    <a href="https://www.facebook.com/coordown" target="_blank" rel="noopener noreferrer">
      <img src="/assets/svg/facebook.svg" alt="Facebook icon link">
    </a>
  </li>
</ul>
</template>

<script>
export default {
  name: 'SocialList',
};
</script>

<style lang="scss" scoped>
.c-social-list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: block;
  }

  img {
    width: 9vw;

    @include mq(ipadP) {
      width: 1.45vw;
    }
  }
}
</style>

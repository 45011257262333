import { gsap } from 'gsap';
import { SplitText } from '@/assets/libs/SplitText';

export function sectionTitleSet(el) {
  const line = el.querySelector('.section-title__line');
  const content = el.querySelector('.section-title__content');
  content.split = new SplitText(content, { type: 'lines, chars', charsClass: 'content__chars', linesClass: 'split-line' });

  gsap.set(content.split.chars, { x: -50, autoAlpha: 0 });
  gsap.set(line, { scaleX: 0, transformOrigin: 'left' });
}

export function sectionTitleIn(el) {
  const line = el.querySelector('.section-title__line');
  const content = el.querySelector('.section-title__content');
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to(line, {
      scaleX: 1,
      duration: window.innerWidth < 768 ? 2.2 : 1.2,
      ease: 'coor',
      clearProps: 'all',
    }, 'start')
    .to(content.split.chars, {
      x: 0,
      duration: 1.2,
      autoAlpha: 1,
      ease: 'coor',
      stagger: {
        from: 'end',
        amount: 0.15,
      },
    }, window.innerWidth < 768 ? 'start' : 'start+=0.2');

  return tl;
}

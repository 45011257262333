<template>
  <transition @enter="enter" @leave="leave">
    <nav class="c-navigator navigator" v-show="$store.state.showMenu">
      <span class="navigator__bg" />
      <ul class="navigator__list">
        <li>
          <router-link to="/about">
            <span class="navigator__number">
              01
            </span>
            <span class="navigator__voice">
              <span class="split-line">
                About
              </span>
            </span>
          </router-link>
        </li>
        <li>
          <router-link to="/share">
            <span class="navigator__number">
              02
            </span>
            <span class="navigator__voice">
              <span  class="split-line">
                Share
              </span>
            </span>
          </router-link>
        </li>
      </ul>

      <section class="navigator__footer">
        <p>Campaign For<br>World Down<br>syndrome Day</p>

        <SocialList class="footer__social" />

        <span class="footer__line" />

        <div class="footer__terms">
          <div>
            <a href="https://www.iubenda.com/privacy-policy/19035635" target="_blank" rel="noopener noreferrer">Terms</a>
            <a href="https://www.iubenda.com/privacy-policy/19035635" target="_blank" rel="noopener noreferrer">Privacy</a>
          </div>

          <p>©2020. Coor Down</p>
        </div>
      </section>
    </nav>
  </transition>
</template>

<script>
import { gsap } from 'gsap';
import SocialList from '@/components/SocialList.vue';
import transitions from '@/scripts/transitions';

export default {
  name: 'Navigator',

  components: {
    SocialList,
  },

  data() {
    return {
      tl: null,
    };
  },

  mounted() {
    this.tl = transitions.get('menu')(this.$el);
  },

  methods: {
    enter(el, done) {
      this.tl
        .timeScale(1)
        .play()
        .then(done);
    },

    leave(el, done) {
      if (this.$store.state.menuClose === 'fromTransition') {
        gsap.set(el, {
          autoAlpha: 0,
          delay: 1,
          onComplete: () => {
            this.tl.seek(0);
            this.tl.pause();
            done();
            gsap.set(el, { autoAlpha: 1 });
          },
        });
      } else {
        this.tl
          .timeScale(2)
          .reverse()
          .then(() => done());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  padding: 12.5vh 6.4vw 6.4vw;

  z-index: 2;

  @extend %grid-column;

  @include mq(ipadP) {
    display: none !important;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: darken($sand, 5%);
  }

  &__list,
  &__footer {
    position: relative;
    grid-column: 1 / -1;

    text-align: center;
  }

  &__list {
    align-self: center;
    text-align: center;

    li:first-child {
      margin-bottom: 3.7vh;
      margin-bottom: calc(var(--vh, 1vh) * 3.7);
    }
  }

  &__voice,
  &__number {
    position: relative;
    display: block;
  }

  &__voice {
    font-family: $font-family-h;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: rem(75px);
    font-size: calc(75px * var(--rw));
    line-height: rem(80px);
    line-height: calc(80px * var(--rw));

    overflow: hidden;

    span {
      display: block;
    }
  }

  &__footer {
    align-self: end;
    font-size: 18px;
    line-height: 1;

    .footer__social {
      position: relative;
      width: 50%;
      margin: 6.15vh auto 0;
      margin: calc(var(--vh, 1vh) * 6.15) auto 0;

      &::v-deep img{
        width: 5.85vw;
      }
    }

    .footer__line {
      display: block;
      width: 100%;
      height: 1px;
      margin: 4.9vh 0 3.7vh;
      margin: calc(var(--vh, 1vh) * 4.9) 0 calc(var(--vh, 1vh) * 3.7);

      background-color: $black;
    }

    .footer__terms {
      font-size: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      a + a {
        margin-left: 15px;
      }
    }
  }
}
</style>

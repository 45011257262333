import { Howler, Howl } from '@/assets/libs/howler/howler.core';

const audio = {
  track: {
    id: 'video-audio',
    file: '/assets/audio/coordown-audio.mp3',
    ref: null,
    loop: false,
    volume: 0.5,
  },

  init() {
    audio.track.ref = new Howl({ src: [audio.track.file], loop: audio.track.loop, volume: audio.track.volume });
  },

  load() {
    return new Promise((resolve) => audio.track.ref.once('load', resolve));
  },

  play() {
    audio.track.ref.play();
  },

  pause() {
    audio.track.ref.pause();
  },

  seek(time) {
    audio.track.ref.seek(time);
  },

  stop() {
    audio.track.ref.stop();
  },

  mute() {
    Howler.mute(true);
  },

  unmute() {
    Howler.mute(false);
  },
};

export default audio;

<template>
  <transition @enter="enter" @leave="leave">
    <aside
      class="c-hire-modal hire__modal"
      v-show="$route.name === 'Hire' && $store.state.showHireModal"
    >
      <span class="modal__bg" />

      <ModalCloseButton
        class="modal__close-button"
        :style="{ 'pointer-events': this.state !== '' ? 'none' : 'auto' }"
        :action="() => $store.commit('CLOSE_HIRE_MODAL')"
      />

      <div class="modal__form">
        <h2 class="form__title">
          <span class="title__first-line">
            Hire in:
          </span>
          <div class="title__flag-cont">
            <span class="title__second-line u-split-container">
              <span>
                {{ form.country }}
              </span>
            </span>
            <div class="title__canvas" ref="canvas" />
          </div>
        </h2>

        <form ref="form" @submit="submit">
          <div class="form__item" data-stagger>
            <input id="address" type="text" name="country" placeholder=" " v-model="form.country">
            <label for="address">
              Your Country <abbr title="required" aria-label="required">*</abbr>
            </label>
            <span class="item__line" />
          </div>

          <div class="form__item" data-stagger>
            <input id="name" type="text" name="name" placeholder=" " required v-model="form.name">
            <label for="name">
              Your Name <abbr title="required" aria-label="required">*</abbr>
            </label>
            <span class="item__line" />
          </div>

          <div class="form__item" data-stagger>
            <input id="email" type="email" name="email" placeholder=" " required v-model="form.email">
            <label for="email">
              Your Email <abbr title="required" aria-label="required">*</abbr>
            </label>
            <span class="item__line" />
          </div>

          <div class="form__item" data-stagger>
            <textarea
              id="request"
              type="text"
              name="request"
              cols="30"
              rows="3"
              placeholder=" "
              required
              v-model="form.message"
            />
            <label for="request">
              I’m interested in hiring a person with Down syndrome and I would like more info. <abbr title="required" aria-label="required">*</abbr>
            </label>
            <span class="item__line" />
          </div>

          <div class="form__item form__item--button">
            <span></span>
            <label class="form__checkbox" data-stagger>
              <input type="checkbox" required>
              <span class="checkmark"></span>
              I accept the
               <a href="https://www.iubenda.com/privacy-policy/19035635" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </label>
            <AppButton @click="$refs.form.submit()" content="Submit" theme="white" type="submit" />
          </div>

          <div class="sending-overlay" v-if="this.state !== ''">
            <transition appear @enter="enterSended">
              <div class="sending-overlay__text" v-if="this.state === 'sended'">
                <span class="u-split-container">
                  <span class="split-line">
                    Email
                  </span>
                </span>
                <span class="u-split-container">
                  <span class="split-line">
                    Sent
                  </span>
                </span>
                <span class="u-split-container">
                  <span class="split-line">
                    Successfuly
                  </span>
                </span>
              </div>
            </transition>
          </div>
        </form>
      </div>

    </aside>
  </transition>
</template>

<script>
import axios from 'axios';
import gsap from 'gsap';
import ModalCloseButton from '@/components/ModalCloseButton.vue';
import AppButton from '@/components/AppButton.vue';
import transitions from '@/scripts/transitions';
import Flag from '@/stage/flag';
import raf from '@/scripts/raf';
import { init, enter } from '@/scripts/animations';

export default {
  name: 'HireModal',

  components: {
    ModalCloseButton,
    AppButton,
  },

  data() {
    return {
      tl: null,
      flagPlaying: false,
      state: '',
      form: {
        country: '',
        name: '',
        email: '',
        message: '',
      },
    };
  },

  mounted() {
    this.flag = new Flag(this.$refs.canvas);
  },

  methods: {
    enter(el, done) {
      if (this.$store.state.hireModalCountry !== '') this.playFlag();
      this.form.country = this.$store.state.hireModalCountry;

      if (this.tl) {
        this.tl.kill();
        this.tl = null;
      }

      this.tl = transitions.get('hireModal')(el, this.form.country);
      this.tl
        .timeScale(1)
        .play()
        .then(done);
    },

    leave(el, done) {
      this.form = {
        country: '',
        name: '',
        email: '',
        message: '',
      };
      this.pauseFlag();
      this.tl
        .timeScale(1.8)
        .reverse()
        .then(done);
    },

    playFlag() {
      this.flagPlaying = true;
      this.flag.setFlag(this.$store.state.hireModalCountry);
      raf.add((t) => this.flag.render(t), 'flag-canvas-hire');
    },

    pauseFlag() {
      raf.remove('flag-canvas-hire');
      this.flagPlaying = false;
    },

    submit(event) {
      event.preventDefault();
      this.$sendEvent('Submit');
      this.state = 'sending';
      axios.get('https://us-central1-coordown-adoratorio.cloudfunctions.net/send', {
        params: this.form,
      }).then(() => {
        this.state = 'sended';
      });
    },

    enterSended(el, done) {
      init.get('bigTitle')(el);

      const tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
      tl.addLabel('start')
        .add(enter.get('bigTitle')(el), 'start')
        .then(() => {
          this.state = '';
          this.form.email = '';
          this.form.name = '';
          this.form.message = '';
          done();
        });
      return tl;
    },
  },

  beforeDestroy() {
    if (this.flagPlaying) raf.remove('flag-canvas-hire');

    this.flag.destroy();
    this.flag = null;
  },
};
</script>

<style lang="scss" scoped>
.c-hire-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100.1);
  padding: 4.9vh 6.4vw 9.85vh;
  padding: calc(var(--vh, 1vh) * 4.9) 6.4vw calc(var(--vh, 1vh) * 9.85);

  color: $white;

  z-index: 30;

  @extend %half-grid-column;

  @include mq(ipadP) {
    padding: 8vh 6.4vw 9.85vh;
  }

  @include mq(ds) {
    width: 50vw;
    padding: 10.6vh 4vw 0;
  }

  .modal__bg {
    display: block;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $black;
  }

  .modal__close-button {
    position: absolute;
    top: 6.4vw;
    right: 6.4vw;

    z-index: 10;

    @include mq(ipadP) {
      top: 4vw;
      right: 4vw;
    }
  }

  .modal__form {
    display: inline-block;
    width: 100%;
    position: relative;

    grid-column: 1 / -1;
    align-self: start;
    justify-self: start;

    @include mq(ipadP) {
      grid-column: 2 / 8;
      padding-right: 2.5vw;
    }
  }

  .form__title {
    margin-bottom: 7.4vh;
    margin-bottom: calc(var(--vh, 1vh) * 7.4);

    .title__first-line {
      display: block;
      font-family: 'Tropiline';
      @include fluid-type(1440px, 3840px, 15px, 53px, 30px, 58px);
    }

    .title__flag-cont {
      position: relative;
      display: inline-block;
    }

    .title__second-line {
      text-transform: uppercase;
      font-family: $font-family-h;
      @include fluid-type(1440px, 3840px, 40px, 106px, 50px, 115px);

      span {
        display: block;
      }
    }

    .title__canvas {
      position: absolute;
      top: 50%;
      left: calc(100% + 30px);
      width: 5vw;
      height: 3.5vw;

      transform: translateY(-50%);

      pointer-events: none;
      display: flex;
      align-items: center;

      canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @include mq(ipadP) {
      margin-bottom: 6.15vh;
      grid-column: 2 / 7;
    }
  }

  .form__item {
    position: relative;
    margin-bottom: 2.8vh;
    margin-bottom: calc(var(--vh, 1vh) * 2.8);

    @include mq($until: ipadP) {
      margin-right: 15vw;
    }

    > span {
      width: 100%;
    }

    label,
    input,
    textarea {
      position: relative;
      display: block;
      width: 100%;
      padding: 3vh 5vw;
      padding: calc(var(--vh, 1vh) * 3) 5vw;
      margin: 0;

      border-radius: 0;
      background-color: transparent;
      font-size: inherit;

      color: $white;

      @include mq($until: ipadP) {
        font-size: 18px;
        line-height: 20px;
      }
      @include mq(ipadP) {
        padding: 25px;
      }
    }

    label {
      position: absolute;
      top: 0;
      left: 0;

      pointer-events: none;
      transition: opacity 0.5s $ease-out,
        transform 0.5s $ease-out;

      color: rgba($white, 0.5);
    }

    .item__line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;

      background-color: $white;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform 0.5s $ease-out;
    }

    @include mq($and: $is-touch-device) {
      textarea {
        margin-bottom: -3px;
      }
    }

    input:not([type="checkbox"]),
    textarea {
      border: 0;
      border-bottom: 1px solid rgba($white, 0.5);

      &:focus {
        outline: transparent;
      }
      &:focus + label,
      &:not(:placeholder-shown) + label {
        opacity: 0;
        transform: translateY(-15px);

        &::before {
          transform: scaleX(1);
        }
      }

      &:focus + label + .item__line,
      &:not(:placeholder-shown) + label + .item__line {
        transform: scaleX(1);
      }
    }

    &--button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        position: absolute;
      }

      @include mq($until: ipadP) {
        align-items: flex-start;
        flex-flow: column;
        margin-right: 0;

        button {
          margin-top: 2.5vh;
          margin-top: calc(var(--vh, 1vh) * 2.5);
        }
      }

      label {
        position: relative;
        font-size: 15px;

        pointer-events: auto;

        input,
        a {
          position: relative;
          width: unset;
          display: inline-block;
        }

        input {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          opacity: 0;
          cursor: pointer;

          @include mq($and: $is-touch-device) {
            padding: 0;
          }
        }

        a {
          @include hover-underline(0, 1px, $white);
          @include mq($and: $is-not-touch-device) {
            transition: color 0.5s $ease-out;

            &:hover {
              color: $white;
            }
          }
        }
      }

      .checkmark {
        position: absolute;
        top: calc(var(--vh, 1vh) * 3.45);
        left: 5vw;
        width: 20px;
        height: 20px;

        border: 1px solid rgba($white, 0.5);
        transition: border 0.5s $ease-out;
        pointer-events: none;

        @include mq(ipadP) {
          top: 50%;
          left: 25px;
          transform: translateY(-50%);
        }

        &::after {
          content: '';
          position: absolute;
          top: -5px;
          left: 2px;
          width: 18px;
          height: 18px;

          background-image: url('/assets/svg/tick.svg');
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;

          transform: scale(0);
          transform-origin: 30% 80%;
          transition: transform 0.5s $ease-out;
        }
      }

      input:checked + .checkmark {
        border: 1px solid $white;

        &::after {
          transform: scale(1);
        }
      }
    }
  }

  .sending-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      @extend %font-slogan;

      > span {
        display: block;

        &:nth-child(1) {
          transform: translateX(5%);
        }

        &:nth-child(2) {
          transform: translateX(22%);
        }

        &:nth-child(3) {
          transform: translateX(-5%);
        }
      }
    }
  }
}
</style>

import { gsap } from 'gsap';
import { bigTitleSet, bigTitleIn } from './bigTitle';
import { fadeSet, fadeIn } from './fade';
import eventHub from '../eventHub';

export function sliderSet(el) {
  const cards = [...el.querySelectorAll('.card')];
  const cardsEyelets = [...el.querySelectorAll('.card__title p')];
  const cardsTitles = [...el.querySelectorAll('.u-split-container')];
  const container = el.querySelectorAll('.cards-slider__container');
  const boundingCardWidth = cards[0].getBoundingClientRect().width + (window.innerWidth * (window.innerWidth < 768 ? 0.055 : 0.11));

  cardsEyelets.forEach((cardsEyelet) => fadeSet(cardsEyelet, { x: 0, y: 50 }));
  cardsTitles.forEach((cardsTitle) => bigTitleSet(cardsTitle));
  gsap.set(container, { left: -1 * (cards[0].getBoundingClientRect().width + el.getBoundingClientRect().left + 5) });
  gsap.set(cards, { x: (index) => (index * boundingCardWidth) + window.innerWidth + boundingCardWidth });
}

export function sliderIn(el) {
  const cards = [...el.querySelectorAll('.card')];
  const cardsEyelets = [...el.querySelectorAll('.card__title p')];
  const cardsTitles = [...el.querySelectorAll('.u-split-container')];
  const boundingWidth = cards[0].getBoundingClientRect().width;
  const boundingCardWidth = cards[0].getBoundingClientRect().width + (window.innerWidth * 0.11);
  const tl = gsap.timeline();

  tl.addLabel('start')
    .to(cards, {
      x: (index) => index * boundingCardWidth,
      y: (index) => `${Math.sin((Math.PI / (window.innerWidth / (window.innerWidth < 768 ? 1.2 : 2))) * ((index * boundingCardWidth) - (boundingWidth + 5))) * 50}px`,
      ease: 'coor',
      duration: 2.5,
      stagger: 0.15,
      pointerEvents: 'none',
    }, 'start')
    .add(() => eventHub.$emit('initSlider'), 2.45);

  cardsEyelets.forEach((cardsEyelet, i) => tl.add(fadeIn(cardsEyelet), `start+=${0.7 + (0.4 * i)}`));
  cardsTitles.forEach((cardsTitle, i) => tl.add(bigTitleIn(cardsTitle), `start+=${0.8 + (0.4 * i)}`));

  return tl;
}

<template>
  <button class="c-modal-close-button" @click="action">
    <svg
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px"
      viewBox="0 0 65 65"
    >
      <circle fill="#EFECEA" cx="32.5" cy="32.5" r="30.6"/>
      <g>
        <path fill="none" stroke="#000" stroke-width="2" d="M26,27l12,12"/>
        <path fill="none" stroke="#000" stroke-width="2" d="M26,39l12-12"/>
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'ModalCloseButton',

  props: {
    action: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.c-modal-close-button {
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 15vw;
    min-width: 56px;

    @include mq(ipadP) {
      width: 4.5vw;
    }
  }
}
</style>

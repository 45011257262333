import Medusa from '@adoratorio/medusa';
import { init, enter } from './animations'; // eslint-disable-line

const medusa = {
  ref: null,

  prevNodes: {
    scrollAnimations: [],
  },

  init() {
    medusa.ref = new Medusa({
      targets: [
        {
          id: 'scrollAnimations',
          container: document.body,
          viewport: document.body.querySelector('.app'),
          nodes: [],
          threshold: 0,
          offsets: window.innerWidth < 768 ? '0px 0px -10% 0px' : '0px 0px -15% 0px',
          mode: Medusa.MODE.ONCE,
          callback: medusa.callbackAnimation,
          emitGlobal: true,
          autoremove: false,
        },
      ],
      debug: false,
    });
  },

  callbackAnimation(event) {
    const { animation } = event.target.dataset;

    enter.get(animation)(event.target).then(() => medusa.removeSingleNode(event.target, 'scrollAnimations'));
  },

  recalculateObservedNodes(elements) {
    medusa.prevNodes.loadImages = [];
    medusa.prevNodes.scrollAnimations = [];
    medusa.prevNodes.parallaxAnimations = [];

    elements.forEach((element) => {
      const { id } = element.dataset;
      element.removeAttribute('data-id');
      medusa.addNode(element, id);
    });
  },

  addNode(element, id) {
    medusa.ref.pushToTarget(id, element);
    medusa.setInitialAnimations(element);

    if (Array.isArray(element)) {
      medusa.prevNodes[id].push(...element);
    } else {
      medusa.prevNodes[id].push(element);
    }
  },

  removeSingleNode(element, id) {
    const elIndexToRemove = medusa.prevNodes[id].findIndex((el) => el._medusaId === element._medusaId); // eslint-disable-line
    if (elIndexToRemove > -1) medusa.prevNodes[id].splice(elIndexToRemove, 1);
  },

  removePrevNodesFromAll() {
    Object.keys(medusa.prevNodes).forEach((key) => {
      medusa.ref.pullFromTarget(medusa.prevNodes[key]);
      medusa.prevNodes[key] = [];
    });
  },

  setInitialAnimations(element) {
    init.get(element.dataset.animation)(element);
  },
};

export default medusa;

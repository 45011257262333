import { fadeSet, fadeIn, fadeOut } from './fade';

export function paragraphSet(el) {
  fadeSet(el, { x: 0, y: 80 });
}

export function paragraphIn(el) {
  return fadeIn(el);
}

export function paragraphOut(el) {
  return fadeOut(el, { y: -80, autoAlpha: 0 });
}

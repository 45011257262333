import { gsap } from 'gsap';
import { fadeSet, fadeIn } from './fade';
import { titleSplitSet, titleSplitIn } from './titleSplit';

export function logoGridSet(el) {
  const title = el.querySelector('.logo-grid__title p');
  const subtitle = el.querySelector('.logo-grid__title em');
  const logos = [...el.querySelectorAll('.logo-grid__item')];

  fadeSet(title, { x: 0, y: 20 });
  titleSplitSet(subtitle);
  fadeSet(logos, { x: 0, y: 80 });
}

export function logoGridIn(el) {
  const title = el.querySelector('.logo-grid__title p');
  const subtitle = el.querySelector('.logo-grid__title em');
  const tl = gsap.timeline();

  tl.addLabel('start')
    .add(fadeIn(title), 'start')
    .add(titleSplitIn(subtitle), 'start+=0.25');

  if (el.classList.contains('logo-grid--thin')) {
    const logos = [...el.querySelectorAll('.logo-grid__item')];

    tl.add(fadeIn(logos, 0.25), 'start+=1');
  } else {
    const lists = [...el.querySelectorAll('.logo-grid__list')];
    const logosLeft = [...lists[0].querySelectorAll('.logo-grid__item')];
    const logosRight = [...lists[1].querySelectorAll('.logo-grid__item')];

    tl.add(fadeIn(logosLeft, 0.25), 'start+=1')
      .add(fadeIn(logosRight, 0.25), 'start+=1.1');
  }

  return tl;
}

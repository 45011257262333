import { fadeSet, fadeIn, fadeOut } from './fade';
import { bigTitleSet, bigTitleIn } from './bigTitle';
import { sectionTitleSet, sectionTitleIn } from './sectionTitle';
import { bigListSet, bigListIn } from './bigList'; // eslint-disable-line
import { paragraphSet, paragraphIn, paragraphOut } from './paragraph';
import { buttonSet, buttonIn } from './button';
import { circularButtonSet, circularButtonIn } from './circularButton';
import { titleSplitSet, titleSplitIn, titleSplitOut } from './titleSplit';
import { carouselArrowSet, carouselArrowIn } from './carouselArrow';
import { logoGridSet, logoGridIn } from './logoGrid';
import { footerSet, footerIn } from './footer';
import { sliderSet, sliderIn } from './slider';

const init = new Map();
init.set('fade', fadeSet);
init.set('bigTitle', bigTitleSet);
init.set('sectionTitle', sectionTitleSet);
init.set('bigList', bigListSet);
init.set('paragraph', paragraphSet);
init.set('button', buttonSet);
init.set('circularButton', circularButtonSet);
init.set('titleSplit', titleSplitSet);
init.set('carouselArrow', carouselArrowSet);
init.set('logoGrid', logoGridSet);
init.set('footer', footerSet);
init.set('slider', sliderSet);

const enter = new Map();
enter.set('fade', fadeIn);
enter.set('bigTitle', bigTitleIn);
enter.set('sectionTitle', sectionTitleIn);
enter.set('bigList', bigListIn);
enter.set('paragraph', paragraphIn);
enter.set('button', buttonIn);
enter.set('circularButton', circularButtonIn);
enter.set('titleSplit', titleSplitIn);
enter.set('carouselArrow', carouselArrowIn);
enter.set('logoGrid', logoGridIn);
enter.set('footer', footerIn);
enter.set('slider', sliderIn);

const out = new Map();
out.set('fade', fadeOut);
out.set('paragraph', paragraphOut);
out.set('titleSplit', titleSplitOut);

export { init, enter, out };
